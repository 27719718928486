/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 27, 2020 */



@font-face {
    font-family: 'berlingske_italic';
    src: url('berlingskesans-it-webfont.woff2') format('woff2'),
         url('berlingskesans-it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'berlingske_regular';
    src: url('berlingskesans-rg-webfont.woff2') format('woff2'),
         url('berlingskesans-rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'berlingske_extrabold';
    src: url('berlingskesans-xbd-webfont.woff2') format('woff2'),
         url('berlingskesans-xbd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'berlingske_con_extrabold';
    src: url('berlingskesanscn-xbd-webfont.woff2') format('woff2'),
         url('berlingskesanscn-xbd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}