$colorBlack: #2f3234;
$colorWhite: #fff;

$colorBackground: #f8f8f9;

$colorDarkred: #af191e;
$colorRed: #fa4650;
$colorOrange: #ff911e;
$colorYellow: #ffe600;
$colorSand: #d2cdb9;
$colorDarkblue: #002364;
$colorBlue: #002dff;
$colorLightblue: #b4dceb;
$colorGreen: #00503c;
$colorMint: #00be96;

.color-bg-darkred {
	background: $colorDarkred !important;
}

.color-bg-red {
	background: $colorRed !important;
}

.color-bg-orange {
	background: $colorOrange !important;
}

.color-bg-yellow {
	background: $colorYellow !important;
}

.color-bg-sand {
	background: $colorSand !important;
}

.color-bg-darkblue {
	background: $colorDarkblue !important;
}

.color-bg-blue {
	background: $colorBlue !important;
}

.color-bg-lightblue {
	background: $colorLightblue !important;
}

.color-bg-green {
	background: $colorGreen !important;
}

.color-bg-mint {
	background: $colorMint !important;
}

.color-bg-white {
	background: $colorWhite !important;
}