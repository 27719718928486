@mixin breakpoint($point) {
    @if $point == lowMediumScreen {
        @media screen and (max-height: 920px) {
            @content;
        }
    }

    @if $point == lowScreen {
        @media screen and (max-height: 700px) {
            @content;
        }
    }

    @if $point == small {
        @media screen and (max-width: 1100px) {
            @content;
        }
    }

    @if $point == mobileXL {
        @media screen and (max-width: 900px) {
            @content;
        }
    }

    @if $point == mobileLarge {
        @media screen and (max-width: 750px) {
            @content;
        }
    }

    @if $point == mobile {
        @media screen and (max-width: 650px) {
            @content;
        }
    }
}