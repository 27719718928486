@import './colors.scss';

html, body, * {
  box-sizing: border-box;
}
  
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $colorBackground;
  overflow-x: hidden;
  overflow-y: scroll;
}

.row {
	.row-inner {

	}
}