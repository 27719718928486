.iframe-wrap {
		position: relative;
		width: 100%;
		padding-bottom: 56.3%;
		margin: 20px 0;

		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}