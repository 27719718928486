@import './../../scss/general/breakpoints.scss';

.App {
  // overflow-x: hidden;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .row-inner {
  	max-width: 100%;
	width: 1280px;
	padding: 0 40px;

	@include breakpoint(mobile) {
		padding: 0 20px;
	}
  }
}