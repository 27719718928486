@import './../../../scss/general/breakpoints.scss';

#navigation {
	padding: 0;
	display: flex;
	background: #00be96;
	margin: 0;
	width: 100%;
	position: fixed;
	z-index: 200;
	top: 0;

	.menu-toggle {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 35px;
		height: 23px;
		padding: 24px 20px;
		cursor: pointer;
		z-index: 100;
		fill: #2f3234;
		box-sizing: content-box;

		@include breakpoint(mobileXL) {
			display: block;
		}
	}

	.row-inner {
		display: flex;
		align-items: center;
		height: 120px;
		position: relative;

		@include breakpoint(lowMediumScreen) {
			height: 90px;
		}

		@include breakpoint(lowScreen) {
			height: 70px;
		}

		@include breakpoint(mobileXL) {
			height: 70px;
		}
	}

	.icon-marker {
		z-index: 100;
		display: flex;

		img {
			height: 50px;
			width: auto;

			@include breakpoint(lowScreen) {
				height: 35px;
			}

			@include breakpoint(mobileXL) {
				height: 35px;
			}
		}
	}

	.mobile-menu-wrapper {
		overflow-y: auto;
		overflow-x: hidden;
		display: none;
		transform: translateY(-100%);
		transition: transform ease 0.3s;
		z-index: 50;

		@include breakpoint(mobileXL) {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100vh;
			width: 100%;
			background: #fff;

			&.show {
				transform: translateY(0);
			}

			.menuitems {
				flex-direction: column;
				transform: none;
				right: inherit;
				top: 0;
				left: 0;
				padding-top: 130px;
				padding-bottom: 60px;
				text-align: right;
				width: 100%;

				li {
					a {
						padding-left: 20px;
						padding-right: 20px;
						font-size: 6vw;
					}
				}
			}
		}
	}

	.menuitems {
		list-style: none;
		display: flex;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);

		&.desktop {
			@include breakpoint(mobileXL) {
				display: none;
			}
		}

		li {

			a {
				padding: 20px 20px;
				font-size: 15px;
				letter-spacing: 0.2px;
				font-family: 'berlingske_extrabold';
				text-decoration: none;
				display: inline-block;
				color: #2f3234;

				@include breakpoint(mobile) {
					padding: 10px 6px;
				}

				span {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						width: 100%;
						height: 2px;
						background: #2f3234;
						bottom: 0;
						left: 0;
						transition: transform ease 0.2s;
						transform: scaleX(0);
						transform-origin: left;
					}
				}


				&:hover, &.active {
					span {
						&:after {
							transform: scaleX(1);
						}
					}
				}
			}
		}
	}

}