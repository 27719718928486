@import './breakpoints.scss';
@import './colors.scss';

body {
	font-family: 'berlingske_regular';
	color: #2f3234;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: 'berlingske_extrabold';
  line-height: 1;
}

h1 {
  text-align: center;
  font-size: 54px;
  line-height: 54px;
  letter-spacing: 0.3px;
  font-family: 'berlingske_con_extrabold';

  @include breakpoint(mobile) {
    font-size: 9vw;
    line-height: 1.1;
  }
}

h2 {
  font-size: 34px;

  @include breakpoint(mobile) {
    font-size: 6vw;
    // line-height: 1.1;
  }
}

h3 {
  font-size: 24px;  
}

h4 {
  font-size: 20px;
}

p, ul {
  line-height: 1.7;
}

a {
  &:focus {
    outline: solid 1px $colorBlack;
  }
}